import { useContext } from "react";
import appContext from "../context/appContext";
import { Box, Text } from "grommet";
import StatusCard from "./StatusCard";
import TextForApplication from "./TextForApplication";
import SourceStatement from "./SourceStatement";
import MoreInformation from "./MoreInformation";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const CostShareWaiver = () => {
  const { cwdgData } = useContext(appContext);

  const isDisadvantaged = cwdgData.disadvantaged;

  const mhi = cwdgData.income.he_mhi;
  const mhi_nation = cwdgData.income.national_mhi;
  const mhi_state = cwdgData.income.he_state_mhi;
  const state_name = cwdgData.income.state;
  const meetsLowIncomeRequirement =
    mhi < 0.8 * mhi_state || mhi < 0.8 * mhi_nation;

  const qualifies = isDisadvantaged || meetsLowIncomeRequirement;

  const geoTypeLabel =
    cwdgData.geoMetadata.geo_level === "county"
      ? "county"
      : cwdgData.geoMetadata.geo_level === "tribal"
      ? "tribal area"
      : "community";

  let QualifierReason = (
    <>
      <>
        {cwdgData.geoMetadata.label}
        {cwdgData.geoMetadata.geo_level === "tribal" ? " " : ", "} is
        {qualifies ? " " : " not"} considered an underserved {geoTypeLabel} and
        is {qualifies ? "" : "not "}
        eligible to apply for a cost-share waiver.
      </>
      {isDisadvantaged && (
        <>
          {" "}
          It is identified as "disadvantaged" in the Climate and Economic
          Justice Screening Tool.
        </>
      )}
      {!isDisadvantaged && meetsLowIncomeRequirement && (
        <>
          {" "}
          It has a median household income of{" "}
          {currencyFormatter.format(cwdgData.income.he_mhi)}.{" "}
          {mhi_state > mhi_nation ? (
            <>
              In order to qualify in {state_name}, locations must have a median
              household income less than{" "}
              {currencyFormatter.format(0.8 * mhi_state)}, which is 80% of{" "}
              {state_name}'s median household income.
            </>
          ) : (
            <>
              In order to qualify in {state_name}, locations must have a median
              household income less than{" "}
              {currencyFormatter.format(0.8 * mhi_nation)}, which is 80% of the
              national median household income.
            </>
          )}
        </>
      )}
      {!qualifies && (
        <>
          {" "}
          It is not identified as "disadvantaged" in the Climate and Economic
          Justice Screening Tool and does not meet the low-income criteria.
        </>
      )}
    </>
  );

  return (
    <StatusCard status={qualifies ? "yes" : "no"}>
      <Box gap="xsmall">
        <Box>
          {qualifies ? (
            <TextForApplication gaCategory={"Cost Share Waiver"}>
              <Text size="small">{QualifierReason}</Text>
              <SourceStatement
                uniqContent={
                  isDisadvantaged
                    ? "Council on Environmental Quality. (2022). Climate and Economic Justice Screening Tool, version 1.0."
                    : "U.S. Census Bureau. (2021). American Community Survey. Washington, DC."
                }
              />
            </TextForApplication>
          ) : (
            <Box>
              <Text size="small">{QualifierReason}</Text>
              <SourceStatement uniqContent="Council on Environmental Quality. (2022). Climate and Economic Justice Screening Tool, version 1.0; and U.S. Census Bureau. (2021). American Community Survey. Washington, DC." />
            </Box>
          )}
        </Box>

        <MoreInformation gaCategory={"Cost Share Waiver"}>
          The U.S. Forest Service may waive the cost-sharing requirement for a
          CWDG project that serves an underserved community. To apply for a
          cost-share waiver, an applicant must demonstrate that the community is
          considered disadvantaged, defined as either: (a) having at least one
          census tract identified as “disadvantaged” in the Climate and Economic
          Justice Screening Tool, or (b) meeting the low-income definition.
          <br />
          <br />
          In addition, nationally recognized Tribes and Pacific Islands are
          eligible for a match waiver.
        </MoreInformation>
      </Box>
    </StatusCard>
  );
};
export default CostShareWaiver;
