import { useContext } from "react";
import appContext from "../context/appContext";
import { useViewport } from "@headwaters-economics/web-shared/contexts/Viewport";

import {
  Box,
  Text,
  DataTable
} from "grommet";
import StatusCard from "./StatusCard";
import { Link } from "grommet-icons";
import TextForApplication from "./TextForApplication";
import SourceStatement from "./SourceStatement";
import MoreInformation from "./MoreInformation";
import TrackedAnchor from "./TrackedAnchor";

const Scoring_Disaster = () => {
  const { cwdgData } = useContext(appContext);
  const { screenSize } = useViewport();
  return (
    <StatusCard status={"na"}>
      <Box>
        <Box gap="xsmall" justify={"start"} direction={"row"} align="baseline">
          <Text size="xsmall" weight={"bolder"}>
            Severe Disaster Impacts
          </Text>
          <Text size="xsmall">10 points</Text>
        </Box>
        <Box>
          <Text size="small">
            Use the table below to find severe disaster(s). Explain in your
            application how previous disasters have impacted wildfire risk in
            your project area. The FEMA identification link will provide more
            information about each disaster.
          </Text>
          {cwdgData.disasters.length > 0 ? (
            <Box background={"light-1"} margin={{ top: "small" }}>
              <Box
                border={{ size: "xsmall", color: "light-4" }}
                round="3px"
                overflow={screenSize === "mobile" ? "scroll" : ""}
                width={screenSize === "mobile" ? "xlarge" : "100%"}
              >
                <DataTable
                  size="medium"
                  fill
                  pad={{ horizontal: "xsmall", vertical: "xxsmall" }}
                  columns={[
                    {
                      property: "declaration_title",
                      header: "Declaration Title",
                      render: (datum) => (
                        <Text size="xsmall">{datum.declaration_title}</Text>
                      ),
                    },
                    {
                      property: "location",
                      header: "Location",
                      render: (datum) => (
                        <Text size="xsmall">{datum.location}</Text>
                      ),
                    },
                    {
                      property: "fy",
                      header: "Year",
                      render: (datum) => <Text size="xsmall">{datum.fy}</Text>,
                      size: "xsmall",
                    },
                    {
                      property: "incident_type",
                      header: "Incident Type",
                      render: (datum) => (
                        <Text size="xsmall">{datum.incident_type}</Text>
                      ),
                      size: "xsmall",
                    },
                    {
                      property: "fema_id",
                      header: "FEMA Identification",
                      render: (datum) => (
                        <TrackedAnchor
                          href={
                            "https://www.fema.gov/disaster/" +
                            datum.disaster_number
                          }
                          gaCategory={"Disaster Table"}
                          title="View disaster information on FEMA's website"
                        >
                          <Link color="neutral-3" size="10px" />{" "}
                          <Text size="xsmall">{datum.fema_id}</Text>
                        </TrackedAnchor>
                      ),
                    },
                  ]}
                  data={cwdgData.disasters}
                  primaryKey="false"
                  className="disasterTable"
                  sortable
                />
                <Box pad={{ horizontal: "xxsmall" }}>
                  <Text size="xsmall">
                    There may be unmatched disasters for your location;{" "}
                    <TrackedAnchor
                      label="click here to download spreadsheet"
                      href={
                        process.env.PUBLIC_URL + "/Unmatched_Disasters.xlsx"
                      }
                      gaCategory={"Disaster Scoring"}
                    />
                    .
                  </Text>
                </Box>
              </Box>
              <Box>
                <TextForApplication gaCategory={"Disaster Scoring"}>
                  <SourceStatement uniqContent="Federal Emergency Management Agency. (2023). Disaster Declarations Summary." />
                </TextForApplication>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box>
                <Text size="small">
                  {cwdgData.geoMetadata.label}
                  {cwdgData.geoMetadata.geo_level === "tribal" ? " " : ", "} has
                  not had federal disaster declarations in the last ten years,
                  according to the FEMA database. There may be unmatched
                  disasters for your location;{" "}
                  <TrackedAnchor
                    label="click here "
                    href="/Unmatched_Disasters.xlsx"
                    gaCategory={"Disaster Scoring"}
                  />
                  to download spreadsheet of disasters that could not disasters
                  that could not be linked to a specific county. You can also
                  use local data (such as state or county declarations, or
                  widespread insect and disease mortality) to demonstrate
                  impacts. In the CWDG application, explain whether and how a
                  previous disaster resulted in increased wildfire risk.
                </Text>
              </Box>

              <Box>
                <SourceStatement uniqContent="Federal Emergency Management Agency. (2023). Disaster Declarations Summary." />
              </Box>
            </Box>
          )}
        </Box>
        <MoreInformation gaCategory={"Disaster Scoring"}>
          The CWDG application allocates 10 points to communities that have been
          impacted by a severe disaster in the previous 10 years. The
          application must demonstrate how the disaster increased wildfire risk
          and was of a scale and scope to have had landscape impacts.
          <br />
          <br />
          Previous disasters may affect wildfire risk in different ways. Your
          application will need to explain how previous disasters increased
          wildfire risk in your project area. For example:
          <br />
          <br />
          <Text weight="bold" size="small">
            Wildfires.
          </Text>{" "}
          Depending on the severity of past wildfires, risk may actually
          decrease because the vegetative fuels are consumed. In some cases,
          however, risk can increase after a wildfire because of a short-term
          increase in fine fuels. After a wildfire, nonnative vegetation can
          grow and is often less fire-resistant.
          <br />
          <br />
          <Text weight="bold" size="small">
            Drought.
          </Text>{" "}
          Drought can increase wildfire risk by reducing fuel moisture.
          Human-caused ignitions are more likely to lead to wildfires during
          droughts.
          <br />
          <br />
          <Text weight="bold" size="small">
            Floods, storms, and wind.
          </Text>{" "}
          Following severe storms, downed trees and vegetation increase the
          continuity and accumulation of fuels and flammable debris and,
          therefore, wildfire risk. Excessive precipitation can cause more
          vegetation to grow, which can become fuel for wildfire when it dries
          out later in the season. Flooding and storms can damage roads,
          bridges, municipal water systems, and other critical infrastructure
          needed to respond to wildfires.
        </MoreInformation>
      </Box>
    </StatusCard>
  );
};
export default Scoring_Disaster;
