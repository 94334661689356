import React from "react";
import { Box, Button, Heading, Text } from "grommet";
import SearchArea from "./Search";
import Eligibility from "./Eligibility";
import Location from "./Location";
import ScoringFire from "./ScoringFire";
import { useContext } from "react";
import appContext from "../context/appContext";
import CostShareWaiver from "./CostShareWaiver";
import Footer from "./Footer";
import ScoringIncome from "./ScoringIncome";
import ScoringDisaster from "./ScoringDisaster";
import SummaryStatement from "./SummaryStatement";
import AppProvider from "../context/AppProvider";
import AppInfo from "./AppInfo";
import GoogleAnalytics from "./GoogleAnalytics";

const CenteredColumn = (props) => {
  return (
    <Box direction="row" justify="center" {...props}>
      <Box width={"large"}>{props.children}</Box>
    </Box>
  );
};

const SectionLayout = ({ label, children }) => {
  return (
    <>
      <Heading level={2}>{label}</Heading>
      <Box pad={{vertical:"xsmall"}} gap="medium" elevation="small" round="xsmall">
        {children}
      </Box>
    </>
  );
};

const AppLayoutWithProvider = ({ storybookState }) => {
  return (
    <AppProvider storybookState={storybookState}>
      <GoogleAnalytics />
      <AppLayout />
    </AppProvider>
  );
};

const AppLayout = () => {
  const { cwdgDataFetchStatus, isEligible } = useContext(appContext);

  return (
    <Box background={"light-2"}>
      <CenteredColumn background={"dark-1"}>
        <SearchArea />
      </CenteredColumn>
      {cwdgDataFetchStatus === "fetching" && (
        <CenteredColumn>Loading...</CenteredColumn>
      )}
      {cwdgDataFetchStatus === "error" && (
        <CenteredColumn>
          {/* //There was an error fetching CWDG data for {}. */}
        </CenteredColumn>
      )}
      {cwdgDataFetchStatus === "fetched" && (
        <>
          <CenteredColumn background={isEligible === "yes" ? "#00873D" : "dark-2"} pad={{vertical: "medium"}}>
            <SummaryStatement />
          </CenteredColumn>
          <CenteredColumn background="light-1" pad={{bottom: "medium"}}>
            <SectionLayout label="Eligibility">
              <Eligibility />
            </SectionLayout>
            <SectionLayout label="GIS Coordinates">
              <Location />
            </SectionLayout>
            <SectionLayout label="Scoring">
              <ScoringIncome />
              <Box height="1px" border={{side: "bottom", size: "xsmall", color: "light-3"}}></Box>
              <ScoringDisaster />
              <Box height="1px" border={{side: "bottom", size: "xsmall", color: "light-3"}}></Box>
              <ScoringFire />
            </SectionLayout>
            <SectionLayout label="Cost-Share Waiver">
              <CostShareWaiver />
            </SectionLayout>
          </CenteredColumn>
          </>
        )}

      <CenteredColumn background="light-4">
        <Box direction="row" align="center" pad={{vertical:"xsmall"}} justify="center" gap="small">
          <Text>Have questions about this Dashboard?</Text>
          <Button target="_blank" plain label={<Box background={"dark-1"} pad={{vertical:"xsmall", horizontal:"small"}} round="3px"><Text size="small" weight={"bold"}>See our FAQ</Text></Box>} href="https://wildfirerisk.org/about/faq/cwdg-tool/"/>
        </Box>
      </CenteredColumn>
      <CenteredColumn>
        <AppInfo/>
      </CenteredColumn>
      <CenteredColumn background="#222">
        <Footer />
      </CenteredColumn>
      
    </Box>
  );
};

export default AppLayoutWithProvider;
