import { useContext } from "react";
import appContext from "../context/appContext";
import { Box, Text } from "grommet";
import StatusCard from "./StatusCard";
import TextForApplication from "./TextForApplication";
import SourceStatement from "./SourceStatement";
import MoreInformation from "./MoreInformation";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const Scoring_Income = () => {
  const { cwdgData } = useContext(appContext);

  const mhi = cwdgData.income.he_mhi;
  const mhi_nation = cwdgData.income.national_mhi;
  const mhi_state = cwdgData.income.he_state_mhi;
  const state_name = cwdgData.income.state;
  const meetsLowIncomeRequirement =
    mhi < 0.8 * mhi_state || mhi < 0.8 * mhi_nation;

  const geoTypeLabel =
    cwdgData.geoMetadata.geo_level === "county"
      ? "County"
      : cwdgData.geoMetadata.geo_level === "tribal"
      ? "Tribal Area"
      : "Community";

  const QualifierStatement =
    mhi_state > mhi_nation ? (
      <>
        In order to qualify in {state_name}, locations must have a median
        household income less than {currencyFormatter.format(0.8 * mhi_state)},
        which is 80% of {state_name}'s median household income.
      </>
    ) : (
      <>
        In order to qualify in {state_name}, locations must have a median
        household income less than {currencyFormatter.format(0.8 * mhi_nation)},
        which is 80% of the national median household income.
      </>
    );

  const IncomeStatement = (
    <>
      It has a median household income of{" "}
      {currencyFormatter.format(cwdgData.income.he_mhi)}. {QualifierStatement}
    </>
  );

  const LeadingStatement =
    meetsLowIncomeRequirement && !cwdgData.income.mhi_geo_level ? (
      <>
        Yes. {cwdgData.geoMetadata.label}
        {cwdgData.geoMetadata.geo_level === "tribal" ? " " : ", "}meets the "low
        income" criteria.{" "}
      </>
    ) : !meetsLowIncomeRequirement && !cwdgData.income.mhi_geo_level ? (
      <>
        No. {cwdgData.geoMetadata.label}
        {cwdgData.geoMetadata.geo_level === "tribal" ? " " : ", "}does not meet
        the "low income" criteria.{" "}
      </>
    ) : meetsLowIncomeRequirement && cwdgData.income.mhi_geo_level !== null ? (
      <>
        Data are not available for {cwdgData.geoMetadata.label}. The county
        where it is located, {cwdgData.income.mhi_geo_level} meets the "low
        income" criteria. {IncomeStatement}
      </>
    ) : meetsLowIncomeRequirement && cwdgData.income.mhi_geo_level !== null ? (
      <>
        Data are not available for {cwdgData.geoMetadata.label}. The county
        where it is location, {cwdgData.income.mhi_geo_level} does not meet the
        "low income" criteria. {IncomeStatement}
      </>
    ) : null;
  return (
    <StatusCard status={meetsLowIncomeRequirement ? "yes" : "no"}>
      <Box gap="xsmall">
        <Box gap="xsmall" justify={"start"} direction={"row"} align="baseline">
          <Text size="xsmall" weight={"bolder"}>
            Low-Income {geoTypeLabel}
          </Text>
          <Text size="xsmall">10 points</Text>
        </Box>
        <TextForApplication gaCategory={"Income Scoring"}>
          <Text size="small">
            {LeadingStatement}
            {IncomeStatement}
          </Text>{" "}
          <SourceStatement
            uniqContent={
              "U.S. Census Bureau. (" +
              cwdgData.income.mhi_year +
              "). American Community Survey. Washington, DC."
            }
          />
        </TextForApplication>
        <MoreInformation gaCategory={"Income Scoring"}>
          The CWDG application allocates 10 points to projects that benefit a
          low-income community. "Low-income" is defined as having a median
          household income less than 80% of the state’s or nation's median
          household income.
        </MoreInformation>
      </Box>
    </StatusCard>
  );
};
export default Scoring_Income;
