import { useContext } from "react";

import appContext from "../context/appContext";
import { useViewport } from "@headwaters-economics/web-shared/contexts/Viewport";
import usfwsLogo from "../assets/logo-usfs.png";
import wrcLogo from "../assets/logo-wrtc.png";
import { Box, Image, Text } from "grommet";
import TrackedAnchor from "./TrackedAnchor"; 
import LocationPicker from "@headwaters-economics/web-shared/components/LocationPicker";

const Search = () => {
  const { screenSize } = useViewport();
  const { selectedGeo, setSelectedGeo } = useContext(appContext);

  const IntroText = () => {
    return (
      <Box direction="column">
        <Text
          color="light-1"
          size="xxlarge"
          weight="bold"
          textAlign={screenSize === "mobile" ? "center" : "start"}
        >
          {" "}
          Community Wildfire Defense Grants
        </Text>
        <Text
          color="light-1"
          textAlign={screenSize === "mobile" ? "center" : "start"}
        >
          Find eligibility and scoring information for your community.
        </Text>
      </Box>
    );
  };

  return (
    <Box
      height={!selectedGeo ? "medium" : ""}
      className="searchBar"
      pad={screenSize === "mobile" ? "medium" : "none"}
    >
      <Box direction="row" justify="end" pad={{top:"xxsmall"}}>
              <TrackedAnchor
                href='https://wildfirerisk.org'
                gaCategory={"General App Info"}
              >
                <Image alignSelf="center" height={"30px"} src={wrcLogo} />
              </TrackedAnchor>
            </Box>
      <Box
        pad={{ top: !selectedGeo ? "xlarge" : "medium", bottom: "medium" }}
        gap="small"
      >
        
        <Box /* Image and Intro Text*/>
          <Box
            direction={screenSize !== "mobile" ? "row" : "column"}
            gap="small"
            align={screenSize === "mobile" ? "center" : ""}
          >
            <Box align="center" justify="center" height="xsmall" width="xsmall">
              <Image alignSelf="center" fill src={usfwsLogo} />
            </Box>
            <IntroText />
          </Box>
        </Box>
        <LocationPicker
          //currentSelection={selectedGeo ? selectedGeo.label : ""}
          geoTypes={["he-county", "he-place", "he-tribal"]}
          placeholder={"Which community, county, or tribal area?"}
          onSelection={(item) => {
            setSelectedGeo(item);
          }}
          table="he.cwdg_v1_1"
        />
      </Box>
    </Box>
  );
};
export default Search;
