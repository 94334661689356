import { useEffect, useRef, useReducer } from "react";
import { getData } from "@headwaters-economics/web-shared/helpers/lambdaHelper";
import _isEqual from "lodash/isEqual";

const useCwdgData = ({ geoID }) => {
    const cache = useRef({});
    const initialState = {
        status: "idle",
        error: null,
        cwdgData: null,
    };

    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case "FETCHING":
                if (state.status === "fetching") return state;
                return { ...initialState, status: "fetching" };
            case "FETCHED":
                if (state.status === "fetched" && _isEqual(state.cwdgData, action.payload)) return state;
                return { ...initialState, status: "fetched", cwdgData: action.payload };
            case "FETCH_ERROR":
                return { ...initialState, status: "error", error: action.payload };
            case "RESET":
                return initialState;
            default:
                return state;
        }
    }, initialState);

    useEffect(() => {
        if (!geoID) {
            dispatch({ type: "RESET" });
            return;
        }
        const fetchData = async () => {
            if (cache.current && cache.current[geoID]) {
                const out = cache.current[geoID];
                dispatch({ type: out === "fetching" ? "FETCHING" : "FETCHED", payload: out });
            } else {
                cache.current[geoID] = "fetching";
                dispatch({ type: "FETCHING" });
                try {
                    const newData = await getData("fetchCwdgData", {
                        id: geoID,
                    });
                    // add the new recods to the cache
                    cache.current[geoID] = newData;
                    dispatch({ type: "FETCHED", payload: newData });
                } catch (error) {
                    dispatch({ type: "FETCH_ERROR", payload: error.message });
                    throw error;
                }
            }
        };

        fetchData();
    }, [geoID]);

    return state;
};

export default useCwdgData;
